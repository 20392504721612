/* eslint no-param-reassign: "error" */
/* eslint-disable no-new */

// *******************************************************************************
// BREAKPOINT VARS
// *******************************************************************************
const breakpointSm = 767;
const breakpointMd = 1023;
const breakpointLg = 1171;

// *******************************************************************************
// VARIOUS FUNCTIONS
// *******************************************************************************

// Return Window Width
function windowWidth() {
  return window.innerWidth || document.documentElement.clientWidth;
}

// *******************************************************************************
// FUNCTIONS
// *******************************************************************************

// Attach Events
function attachEvent(selector, event, handler) {
  document.addEventListener(
    event,
    (ev) => {
      let { target } = ev;
      for (; target && target !== document; target = target.parentNode) {
        if (target.matches(selector)) {
          try {
            handler.call(target, ev);
          } catch (e) {
            console.error(e);
          }
          break;
        }
      }
    },
    false,
  );
}

// Animate
function animate(elementToAnimate, type) {
  switch (type) {
    case 'show':
      elementToAnimate.classList.add('is-visible');
      elementToAnimate.classList.remove('is-hidden');
      break;
    case 'hide':
      elementToAnimate.classList.add('is-hidden');
      elementToAnimate.classList.remove('is-visible');
      break;
    default:
      break;
  }
}

// *******************************************************************************
// DEBOUNCE FUNCTION
// *******************************************************************************

function debounce(func, wait, immediate) {
  let timeout;
  return function (...args) {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

// *******************************************************************************
// SLIDE FUNCTIONS
// *******************************************************************************

const slideUp = (target, duration) => {
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = `${duration}ms`;
  target.style.boxSizing = 'border-box';
  target.style.height = `${target.offsetHeight}px`;
  // eslint-disable-next-line no-unused-expressions
  target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout(() => {
    target.style.display = 'none';
    target.style.removeProperty('height');
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
};

const slideDown = (target, duration, displayType) => {
  target.style.removeProperty('display');

  let { display } = window.getComputedStyle(target);

  // eslint-disable-next-line no-unused-expressions
  display === 'none' ? (display = displayType) : null;
  target.style.display = display;

  target.style.display = display;
  const height = target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  // eslint-disable-next-line no-unused-expressions
  target.offsetHeight;
  target.style.boxSizing = 'border-box';
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = `${duration}ms`;
  target.style.height = `${height}px`;
  target.style.removeProperty('padding-top');
  target.style.removeProperty('padding-bottom');
  target.style.removeProperty('margin-top');
  target.style.removeProperty('margin-bottom');
  window.setTimeout(() => {
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
};

const slideToggle = (target, duration, displayType) => {
  /* Slide-toggle logic */

  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration, displayType);
    // eslint-disable-next-line no-else-return
  } else {
    return slideUp(target, duration, displayType);
  }
};

// *******************************************************************************
// 0.7 - Smooth Scroll
// *******************************************************************************

function calcVh() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const debounceCalcVh = debounce(() => {
  calcVh();
}, 50);

// Only mobile has VH calculation
window.addEventListener('DOMContentLoaded', () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    calcVh();
    if (window.screen.orientation) {
      window.screen.orientation.onchange = function () {
        debounceCalcVh();
      };
    } else {
      // Fallback for Safari, because it doesn't support screen.orientation
      window.onorientationchange = function () {
        debounceCalcVh();
      };
    }
  }
});

// *******************************************************************************
// Smooth Scroll
// *******************************************************************************

const headerEle = document.querySelector('.header-main');

function scrollToSmoothly(pos, time = 500) {
  const currentPos = window.pageYOffset;
  let start = null;

  const step = (currentTime) => {
    start = !start ? currentTime : start;
    const progress = currentTime - start;
    const positionUpY = currentPos - ((currentPos - pos) * progress) / time;
    const positionDownY = ((pos - currentPos) * progress) / time + currentPos;

    window.scrollTo(0, currentPos < pos ? positionDownY : positionUpY);

    if (progress < time) {
      window.requestAnimationFrame(step);
    } else {
      window.scrollTo(0, pos);
    }
  };

  window.requestAnimationFrame(step);
}

document.addEventListener('DOMContentLoaded', async () => {
  setTimeout(() => {
    document.body.classList.add('page-loaded');
  }, 50);
});
