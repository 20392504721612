/* global Cookies, attachEvent */

const cookieElement = document.querySelector('.cookie-banner');
const loadScripts = () => {
  const scripts = document.querySelectorAll('.sp-cookies.gtm');
  scripts.forEach((script) => {
    // eslint-disable-next-line no-param-reassign
    script.type = 'text/javascript';
    document.head.appendChild(script);
  });
};

if (cookieElement) {
  if (Cookies.get('cookie-banner-seen')) {
    cookieElement.classList.add('cookie-set');
  } else {
    cookieElement.classList.remove('cookie-set');
  }

  attachEvent('.btn--cookie', 'click', (ev) => {
    loadScripts();
    Cookies.set('cookie-banner-seen', 'cookie-set', { expires: 7 });
    cookieElement.classList.add('cookie-set');
    ev.preventDefault();
  });
}

if (!cookieElement) {
  loadScripts();
}
